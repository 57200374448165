<template>
  <div id="listings-wrapper" ref="listingsWrapper">
    <ListItem
      v-for="(garden, gardenIndex) in sortedGarden"
      :key="gardenIndex"
      :garden="garden"
    />
    <div :class="{'hide' : !noGardens}" class="no-listings-message row">
      <div class="col-12 mt-4 mb-3">
        <h3>{{ noGardensTitle }}</h3>
      </div>
      <div class="col-6">
        <b-button @click="setSearchType" class="garden-search-button mb-3">
          <font-awesome-icon :icon="unSelectedSearchType.faIcon"/>
            {{ noButtonText }}
        </b-button>
        <p class="button-instruction">{{ noGardensInstruction }}</p>
      </div>
      <div class="col-6">
        <b-button class="zoom-out-button mb-3" variant="primary">
          <font-awesome-icon :icon="['far', 'search-minus']"/>
            Zoom Out
        </b-button>
        <p class="button-instruction">Zoom out on the map to search a wider radius or amend the date range<br>(currently set to '{{ currentDateRange }}')</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { clone } from 'lodash'
import ListItem from '@/components/gardenList/ListItem'
import eventBus from '@/services/eventBus'

export default {
  name: 'Listings',

  data: () => ({
    changed: true,
    searchedTerm: null,
  }),

  components: {
    ListItem,
  },

  async mounted() {
    this.searchedTerm = clone(this.searchTerm)
    // adding eventBus listener
    eventBus.$on('gardens-filtered', () => {
      this.searchedTerm = clone(this.searchTerm)
      this.changed = true
    })
    eventBus.$on('search-type-changed', () => {
      this.changed = false
    })
  },
  beforeDestroy() {
    // removing eventBus listener
    eventBus.$off('gardens-filtered')
    eventBus.$off('search-type-changed')
  },

  computed: {
    ...mapGetters('garden', [
      'selectedSearchType',
      'unSelectedSearchType',
      'locationSearchType',
      'gardenSearchType',
      'sortedGarden',
      'searchTypeValue',
      'searchTerm',
    ]),
    ...mapGetters('filter', [
      'sectionFilterSelected',
    ]),
    currentDateRange() {
      const selectedDateRange = this.sectionFilterSelected('days_open');

      if (selectedDateRange.length) {
        return selectedDateRange.charAt(0).toUpperCase() + selectedDateRange.slice(1).replaceAll('-', ' ');
      }

      const preSelectedFromDate = this.sectionFilterSelected('date_open_from');
      const preSelectedToDate = this.sectionFilterSelected('date_open_to');

      if (preSelectedFromDate && preSelectedToDate) {
        return `${new Date(preSelectedFromDate).toLocaleDateString()} - ${new Date(preSelectedToDate).toLocaleDateString()}`;
      }

      return '';
    },
    noGardens() {
      return this.sortedGarden.length === 0 && this.changed;
    },
    noGardensTitle() {
      return this.searchTypeValue === 'garden' && this.searchedTerm ? `We didnt find any gardens titled "${this.searchedTerm}"` : 'We didn’t find any gardens in this location'
    },
    noGardensInstruction() {
      return this.searchTypeValue === 'garden' ? 'If you are searching for a location, try searching for the location name by clicking here' : 'If you are searching for a garden, try searching for the garden name by clicking here'
    },
    noButtonText() {
      return this.searchTypeValue === 'garden' ? 'Search by town or postcode' : 'Search by garden name'
    },
  },

  watch: {
    sortedGarden(newGardens, oldGardens) {
      if (newGardens && newGardens.length && oldGardens.length && (newGardens !== oldGardens)) {
        this.$refs.listingsWrapper.scrollTo({ top: 0, behavior: 'smooth' })
      }
    },
  },

  methods: {
    ...mapActions('garden', [
      'updateSearchType',
      'search',
    ]),

    async setSearchType() {
      this.changed = false
      const searchType = this.searchTypeValue === 'garden' ? this.locationSearchType : this.gardenSearchType
      await this.updateSearchType(searchType)
      this.search(this.$refs.$children)

      const searchInputs = document.querySelectorAll('.search-input-component');

      if (searchInputs?.length) {
        if (window.innerWidth >= 992) {
          searchInputs[0].focus()
        } else {
          searchInputs[1].focus()
        }
      }

      this.$bvModal.hide('search-modal')
    },
  },
}
</script>

<style scoped lang="scss">
.hide {
  display: none !important;
}

#listings-wrapper {
  @include media-breakpoint-up(lg) {
    flex-grow: 1;
    overflow: scroll;
  }

  .no-listings-message {
    margin: 1rem 3rem 0 3rem;

    button {
      font-family: "NGS 2017", "Inter", sans-serif !important;
    }

    .garden-search-button {
      background-color: $color-green-main;
      color: $color-white-main;
    }

    p {
      line-height: 1;
    }
  }
}
</style>
